// @flow

import React from "react";
import {
    CreateInspectionLayout,
    InspectionOverview as _InspectionOverview,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const InspectionOverview = (props: *) => (
    <Layout {...props}>
        <CreateInspectionLayout>
            <_InspectionOverview />
        </CreateInspectionLayout>
    </Layout>
);
export default withPrivateRoute(InspectionOverview);
